import { Icon } from 'components/UI/Icon';
import Link from 'components/UI/Link/Link';
import { Typography } from 'components/UI/Typography';
import styles from './widget-topic_page_links-default.module.scss';

export type WidgetStorylineTopicTagsProps = {
  readonly model: {
    readonly tagpages: ReadonlyArray<{ title: string; url: string }>;
  };
};
export default function WidgetTopicPageLinks({ context }: { context: WidgetStorylineTopicTagsProps }) {
  const { tagpages } = context.model;

  if (!tagpages || tagpages.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.root}>
      <Icon name="topics" size={28} />
      {tagpages.map((tag) => (
        <Typography key={tag.title} as="h2">
          <Link className={styles.link} href={tag.url}>
            {tag.title}
          </Link>
        </Typography>
      ))}
    </div>
  );
}
